var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"market_details_content"},[_c('div',{staticClass:"subject"},[_c('div',{staticClass:"title"},[_c('img',{staticClass:"title_img",attrs:{"src":_vm.List.nftPath,"alt":""}}),_c('div',{staticClass:"title_content"},[_c('div',{staticClass:"title_name",style:(_vm.List.code.length >= 11 ? 'font-size: 34px;' : 'font-size: 70px;')},[_vm._v(_vm._s(_vm.List.code))]),_c('div',{staticClass:"title_logo",staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"title_solana"},[(_vm.List.nftId)?_c('img',{attrs:{"src":_vm.List.chain === 'Solana'
                  ? '/img/SOLANA.png'
                  : _vm.List.chain === 'Ethereum'
                  ? '/img/marketgroup9copy.png'
                  : '/img/marketgroup10copy.png',"alt":""}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.List.chain))])]),_c('img',{staticStyle:{"width":"218px"},attrs:{"src":_vm.introduce.logo,"alt":""}})]),_c('div',{staticClass:"introduce"},[_c('div',{staticClass:"introduce_top"},_vm._l((_vm.attr),function(item){return _c('div',{key:item.index,staticClass:"introduce_text"},[_c('p',{attrs:{"title":item.head}},[_vm._v(_vm._s(item.head))]),_c('p',{staticStyle:{"color":"#fff"},attrs:{"title":item.body}},[_vm._v(_vm._s(item.body))])])}),0)]),_c('div',{staticClass:"buy"},[(_vm.List.nftId)?_c('div',{staticClass:"buy_left"},[_c('p',[_vm._v(_vm._s(_vm.priceDispose(_vm.List.price) + ' ' + _vm.List.currency))]),_c('img',{attrs:{"src":"/img/Group@2x.png","alt":""},on:{"click":_vm.PayListBtn}})]):_vm._e(),_c('div',{staticClass:"buy_right",on:{"click":function($event){return _vm.updateLike(_vm.List)}}},[_c('div',{staticStyle:{"cursor":"pointer","display":"flex","flex-direction":"column","align-items":"center","justify-content":"center"}},[(_vm.List.nftId)?_c('img',{attrs:{"src":_vm.LikeList.some(function (v) { return v.nftId === _vm.List.nftId; })
                    ? '/img/like@2x.png'
                    : '/img/market_zan.png',"alt":""}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.List.likes))])])])])])]),_c('div',{staticClass:"middle"},[_vm._v(" "+_vm._s(_vm.introduce.description)+" ")]),(_vm.swiperList.length !== 0)?_c('div',{staticClass:"series"},[_c('p',{staticClass:"series_title"},[_vm._v("Same Series")]),_c('swiper',{ref:"mySwiper",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.swiperList),function(item,index){return _c('swiper-slide',{key:index},[_c('div',{staticClass:"swiperContent"},[_c('div',{staticClass:"series_img"},_vm._l((item),function(value){return _c('div',{key:value.nftId,staticClass:"right_img"},[_c('div',{staticClass:"top",on:{"click":function($event){return _vm.detailsBtn(value)}}},[_c('img',{staticClass:"img",attrs:{"src":value.nftPath,"alt":""}}),_c('img',{staticClass:"search",attrs:{"src":"/img/market_zoom.png","alt":""},on:{"click":function($event){$event.stopPropagation();return _vm.magnifyBtn(value.nftPath)}}}),_c('div',{staticClass:"Solana newSolana"},[_c('img',{attrs:{"src":value.chain === 'Solana'
                          ? '/img/SOLANA.png'
                          : value.chain === 'Ethereum'
                          ? '/img/marketgroup9copy.png'
                          : '/img/marketgroup10copy.png',"alt":""}}),_c('span',[_vm._v(_vm._s(value.chain))])])]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"name_price"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(value.code))]),_c('p',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.priceDispose(value.price) + ' ' + value.currency)+" ")])]),_c('div',{staticClass:"zan",on:{"click":function($event){return _vm.updateLike(value)}}},[_c('img',{attrs:{"src":_vm.LikeList.some(function (v) { return v.nftId === value.nftId; })
                          ? '/img/like@2x.png'
                          : '/img/market_zan.png',"alt":""}}),_c('span',[_vm._v(_vm._s(value.likes))])])])])}),0)])])}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2)],1):_vm._e()]),_c('div',{staticClass:"ending",staticStyle:{"line-height":"18px"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('marketDetails.explain1'))+" ")]),_c('br'),_c('p',{staticStyle:{"margin-bottom":"25px"}},[_vm._v(" "+_vm._s(_vm.$t('marketDetails.explain2'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('marketDetails.explain3'))+" ")]),_c('br'),_c('p',[_vm._v(" "+_vm._s(_vm.$t('marketDetails.explain4'))+" ")]),_c('br'),_c('p',[_vm._v(" "+_vm._s(_vm.$t('marketDetails.explain5'))+" ")]),_c('br'),_c('p',[_vm._v(_vm._s(_vm.$t('marketDetails.explain6')))])]),_c('el-dialog',{attrs:{"width":"35%","visible":_vm.dialogVisible},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('img',{attrs:{"width":"100%","src":_vm.dialogImageUrl,"alt":""}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }