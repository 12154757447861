<template>
  <div class="market_details_content">
    <div class="subject">
      <div class="title">
        <img class="title_img" :src="List.nftPath" alt="" />
        <div class="title_content">
          <!-- {{ List.code }} -->
          <div class="title_name" :style="List.code.length >= 11 ? 'font-size: 34px;' : 'font-size: 70px;'">{{ List.code }}</div>
          <div class="title_logo" style="margin-top: 10px;">
            <div class="title_solana">
              <img
              v-if="List.nftId"
                :src="
                  List.chain === 'Solana'
                    ? '/img/SOLANA.png'
                    : List.chain === 'Ethereum'
                    ? '/img/marketgroup9copy.png'
                    : '/img/marketgroup10copy.png'
                "
                alt=""
              />
              <span>{{ List.chain }}</span>
            </div>
            <img style="width: 218px; 70px;" :src="introduce.logo" alt="">
          </div>
          <div class="introduce">
            <div class="introduce_top">
              <div
                v-for="item in attr"
                :key="item.index"
                class="introduce_text"
              >
                <p :title="item.head">{{ item.head }}</p>
                <p style="color: #fff;" :title="item.body">{{ item.body }}</p>
              </div>
            </div>
          </div>
          <div class="buy">
            <div class="buy_left" v-if="List.nftId">
              <p>{{ priceDispose(List.price) + '&nbsp;' + List.currency }}</p>
              <img src="/img/Group@2x.png" alt="" @click="PayListBtn" />
            </div>
            <div @click="updateLike(List)" class="buy_right">
              <div
                style="
                  cursor: pointer;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                "
              >
                <img
                 v-if="List.nftId"
                  :src="
                    LikeList.some((v) => v.nftId === List.nftId)
                      ? '/img/like@2x.png'
                      : '/img/market_zan.png'
                  "
                  alt=""
                />
                <span>{{ List.likes }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="middle">
        {{ introduce.description }}
      </div>
      <div class="series" v-if="swiperList.length !== 0">
        <p class="series_title">Same Series</p>
        <swiper :options="swiperOption" ref="mySwiper">
          <swiper-slide v-for="(item, index) in swiperList" :key="index">
            <div class="swiperContent">
              <div class="series_img">
                <div class="right_img" v-for="value in item" :key="value.nftId">
                  <div class="top" @click="detailsBtn(value)">
                    <img class="img" :src="value.nftPath" alt="" />
                    <img
                      @click.stop="magnifyBtn(value.nftPath)"
                      class="search"
                      src="/img/market_zoom.png"
                      alt=""
                    />
                    <div class="Solana newSolana">
                      <img
                        :src="
                          value.chain === 'Solana'
                            ? '/img/SOLANA.png'
                            : value.chain === 'Ethereum'
                            ? '/img/marketgroup9copy.png'
                            : '/img/marketgroup10copy.png'
                        "
                        alt=""
                      />
                      <span>{{ value.chain }}</span>
                    </div>
                  </div>
                  <div class="bottom">
                    <div class="name_price">
                      <p class="name">{{ value.code }}</p>
                      <p class="price">
                        {{ priceDispose(value.price) + '&nbsp;' + value.currency }}
                      </p>
                    </div>
                    <div class="zan" @click="updateLike(value)">
                      <img
                        :src="
                          LikeList.some((v) => v.nftId === value.nftId)
                            ? '/img/like@2x.png'
                            : '/img/market_zan.png'
                        "
                        alt=""
                      />
                      <span>{{ value.likes }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev">
          </div>
          <div class="swiper-button-next" slot="button-next">
          </div>
        </swiper>
      </div>
    </div>
    <div class="ending" style="line-height: 18px;">
      <p>
        {{ $t('marketDetails.explain1') }}
      </p>
      <br>
      <p style="margin-bottom: 25px;">
        {{ $t('marketDetails.explain2') }}
        </p>
      <p>
        {{ $t('marketDetails.explain3') }}
      </p>
      <br>
      <p>
        {{ $t('marketDetails.explain4') }}
        </p>
      <br>
      <p>
        {{ $t('marketDetails.explain5') }}
      </p>
      <br>
      <p>{{ $t('marketDetails.explain6') }}</p>
    </div>
    <el-dialog width="35%" :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
Array.prototype.indexOf = function(val) {
  for (var i = 0; i < this.length; i++) {
    if (this[i] == val) return i
  }
  return -1
}
Array.prototype.remove = function(val) {
  var index = this.indexOf(val)
  if (index > -1) {
    this.splice(index, 1)
  }
}
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import {
  marketDetails,
  updateLike,
  getSysLikeList,
  marketList,
  createNftCommodityOrder,
  getCategoryData,
} from '../../api/api'
import { mapState } from 'vuex'
import { newAttr, priceDispose } from '../../common/common'
export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      textLength: false, // 判断标题长度
      priceDispose,
      dialogVisible: false,
      dialogImageUrl: '',
      List: {
        code: ''
      },
      introduce: [], // 商品描述信息
      id: this.$route.query.nftCommodityId,
      attr: [],
      swiperList: [],
      LikeList: [],
      // [ { head: 'black', body: 'test' },{ head: 'black2', body: 'test2' },{ head: 'black3', body: 'test3' } ],
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3500,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        speed: 800,
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  computed: {
    ...mapState(['userInfo']),
    swiper() {
      return this.$refs.mySwiper.swiper
    },
  },

  watch: {
    $route(to, from) {
      if(to.query.nftCommodityId !== from.query.nftCommodityId) {
        this.marketDetails(to.query.nftCommodityId)
      }
    },
  },
  created() {
    if(!this.List.code) {
      this.marketDetails().then(() => {
        this.marketList()
        this.getCategoryData()
      })
    }
  },
  methods: {
    async getCategoryData() {
      const res = await getCategoryData(this.List.category)
      this.introduce = res.data[0]
    },
    // 改造轮播图数据
    newSwiperList(List) {
      let result = []
      for (let i = 0; i < List.length; i += 3) {
        result.push(List.slice(i, i + 3))
      }
      return result
    },
    async marketList() {
      const res = await marketList({
        chain: [this.List.chain],
        status: 'enabled',
      })
      res.data.result.forEach((item) => {
        if (item.nftId === this.List.nftId) {
          res.data.result.remove(item)
        }
      })
      this.swiperList = this.newSwiperList(res.data.result)
    },
    async getSysLikeList(res) {
      // const res = await getSysLikeList()
      this.LikeList = res.data
    },
    magnifyBtn(url) {
      this.dialogVisible = true
      this.dialogImageUrl = url
    },
    detailsBtn(obj) {
      this.List.nftId = obj.nftId
      this.id = obj.nftCommodityId
      this.marketList()
      this.$router.push(
        `/nftmarket/marketDetails?nftCommodityId=${obj.nftCommodityId}`
      )
      // this.marketDetails()
      window, scrollTo(0, 0)
    },
    async updateLike(obj) {
      let isLike = null
      let LikeList = this.LikeList
      if (LikeList.length === 0) {
        isLike = '1'
      } else {
        var a = LikeList.some((item) => {
          return item.nftId === obj.nftId
        })
      }
      if (a) {
        isLike = '0'
      } else {
        isLike = '1'
      }
      const res = await updateLike({
        mobile: this.userInfo.name + this.userInfo.mobile,
        nftId: obj.nftId,
        isLike,
      })
      this.marketDetails()
      this.marketList()
      this.$message.success(res.message)
    },
    async marketDetails(data) {
      const marketDetails2 =  marketDetails(data ? data : this.id)
      const getSysLikeList2 =  getSysLikeList()
      const res = await marketDetails2
      const res2 = await getSysLikeList2
      this.List = res.data
      this.getSysLikeList(res2)
      this.attr = newAttr(this.List.attr)
    },
    async PayListBtn() {
      if (this.List.status !== 'enabled') return this.$message.warning(this.$t('message.orderNo'))
      const res = await createNftCommodityOrder({
        nftId: parseInt(this.List.nftId),
      })
      this.$router.push(
        `/nftmarket/marketPay?nftId=${this.List.nftId}&nftCommodityId=${this.id}&orderNo=${res.data.orderNo}`
      )
    },
  },
}
</script>

<style></style>
